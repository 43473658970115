<script setup lang="ts">
useHead({
  htmlAttrs: {
    lang: 'en',
  },
})
</script>

<template>
  <main>
    <slot />
  </main>
</template>
